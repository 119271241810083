import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
} from "@mui/material";
import AddSecretKey from "../components/secretkey/AddSecretKey";
import DeleteSecretKey from "../components/secretkey/DeleteSecretKey";
import EditSecretKey from "../components/secretkey/EditSecretKey";
//
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SecretKey = () => {
  const { url } = useContext(AppContext);
  const [secretKeys, setSecretKeys] = useState([]);
  const [secretKeys2, setSecretKeys2] = useState([]);
  const getSecretKeys = async () => {
    const dm_admin = Cookies.get("dm_admin");
    try {
      const res = await axios.get(`${url}/secretkey`, {
        headers: {
          token: dm_admin,
        },
      });
      if (res.data.success === true) {
        setSecretKeys(res.data.result);
        setSecretKeys2(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSecretKeys();
  }, []);
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [addSecretkeyModal, setAddSecretKeyModal] = useState(false);
  //
  const [deleteSecretKeymodal, setDeleteSecretKeymodal] = useState(false);
  const [confirmSecretKey, setConfirmSecretKey] = useState(null);
  const openDeleteModal = (item) => {
    setDeleteSecretKeymodal(true);
    setConfirmSecretKey(item);
  };
  const deleteSecretKey = async () => {
    const dm_admin = Cookies.get("dm_admin");
    try {
      const res = await axios.delete(
        `${url}/secretkey/${confirmSecretKey._id}`,
        {
          headers: {
            token: dm_admin,
          },
        }
      );
      if (res.data.success === true) {
        getSecretKeys();
        setDeleteSecretKeymodal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [editSecretKeyModal, setEditSecretKeymodal] = useState(false);
  const [edit, setEdit] = useState("");
  const [editChecked, setEditChecked] = useState(false);
  //
  const openEditModal = async (item) => {
    const dm_admin = Cookies.get("dm_admin");
    try {
      const res = await axios.get(`${url}/secretkey/${item._id}`, {
        headers: {
          token: dm_admin,
        },
      });
      //
      if (res.data.success === true) {
        setEdit(res.data.result.secret_key);
        setEditChecked(res.data.result.active);
      }
    } catch (error) {
      console.log(error);
    }
    setEditSecretKeymodal(true);
    setConfirmSecretKey(item._id);
  };
  //
  const [searchInput, setSearchInput] = useState("");
  const [checkStatus, setCheckStatus] = useState("");
  const applyFilter = () => {
    let updated = secretKeys;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return item.secret_key
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    }
    //
    if (checkStatus !== "") {
      updated = updated.filter((item) => {
        return item.active == checkStatus;
      });
    }
    //
    setSecretKeys2(updated);
  };
  useEffect(() => {
    applyFilter();
  }, [checkStatus]);
  return (
    <>
      <div className="container-fluid p-2">
        <div className="secret_key_bar mb-2">
          <div className="first">
            <TextField
              label="Search"
              variant="standard"
              fullWidth
              autoComplete="off"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={applyFilter}
            />
          </div>

          <div className="second">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              variant="standard"
              fullWidth
              value={checkStatus}
              onChange={(e) => setCheckStatus(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>

            <div>
              <IconButton
                color="primary"
                aria-label="upload file"
                component="span"
                onClick={() => setAddSecretKeyModal(true)}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>

        {/*  */}
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-background">
                <TableCell className="border">Sr No</TableCell>
                <TableCell className="border">Secret key</TableCell>
                <TableCell className="border">Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {secretKeys2.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <TableCell className="border">{index + 1}</TableCell>
                    <TableCell className="border">{item.secret_key}</TableCell>
                    <TableCell
                      className="border"
                      sx={{ color: item.active === true ? "green" : "red" }}
                    >
                      {item.active === true ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell className="border text-end">
                      <ButtonGroup>
                        <IconButton
                          color="primary"
                          onClick={() => openEditModal(item)}
                        >
                          <CreateIcon />
                        </IconButton>
                        <IconButton
                          color="danger"
                          onClick={() => openDeleteModal(item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <AddSecretKey
        addSecretkeyModal={addSecretkeyModal}
        setAddSecretKeyModal={setAddSecretKeyModal}
        getSecretKeys={getSecretKeys}
      />

      <DeleteSecretKey
        deleteSecretKeymodal={deleteSecretKeymodal}
        setDeleteSecretKeymodal={setDeleteSecretKeymodal}
        confirmSecretKey={confirmSecretKey}
        deleteSecretKey={deleteSecretKey}
      />

      <EditSecretKey
        editSecretKeyModal={editSecretKeyModal}
        setEditSecretKeymodal={setEditSecretKeymodal}
        confirmSecretKey={confirmSecretKey}
        getSecretKeys={getSecretKeys}
        edit={edit}
        setEdit={setEdit}
        editChecked={editChecked}
        setEditChecked={setEditChecked}
      />
    </>
  );
};

export default SecretKey;
