import React, { useContext, useState } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import Cookies from "js-cookie";

const DeleteUserModal = ({
  deleteUserModal,
  setDeleteUsermodal,
  confirmUser,
  getUsers,
}) => {
  const { url } = useContext(AppContext);
  const [message, setMessage] = useState("");
  const deleteUser = async () => {
    try {
      const dm_admin = Cookies.get("dm_admin");
      const res = await axios.delete(`${url}/user/${confirmUser._id}`, {
        headers: {
          token: dm_admin,
        },
      });
      if (res.data.success === true) {
        setDeleteUsermodal(false);
        getUsers();
      } else if (res.data.success === false) {
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={deleteUserModal}
        onClose={() => setDeleteUsermodal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <p className="mb-2">Delete {confirmUser && confirmUser.name}?</p>
          </>
          <p className="text-danger">{message}</p>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              color="danger"
              onClick={deleteUser}
              variant="contained"
              className="text-white"
            >
              Yes
            </Button>
            <Button
              onClick={() => setDeleteUsermodal(false)}
              color="primary"
              variant="contained"
              className="text-white"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteUserModal;
