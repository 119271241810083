import React, { createContext, useState } from "react";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = "https://api.parmarketing.co.in";
  const [admin, setAdmin] = useState(null);
  return (
    <AppContext.Provider
      value={{
        url,
        admin,
        setAdmin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
