import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import Cookies from "js-cookie";

const EditLead = () => {
  const { url } = useContext(AppContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState(null);
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const getLead = async () => {
    const dm_admin = Cookies.get("dm_admin");
    try {
      const res = await axios.get(`${url}/lead/admin/${id}`, {
        headers: {
          token: dm_admin,
        },
      });
      if (res.data.success === true) {
        setInputs(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLead();
  }, []);
  //
  const editLead = async () => {
    const dm_admin = Cookies.get("dm_admin");
    const {
      category,
      tag,
      name,
      email,
      phone1,
      phone2,
      phone3,
      whatsapp,
      city,
      pincode,
      address1,
      address2,
      address3,
      source,
      aadhar,
      pan,
      occupation,
      homeOwner,
      creditScore,
      companyName,
      gst,
      callStatus,
      smsStatus,
      emailStatus,
      whatsappStatus,
    } = inputs;
    try {
      const res = await axios.put(
        `${url}/lead/${id}`,
        {
          category,
          tag,
          name,
          email,
          phone1,
          phone2,
          phone3,
          whatsapp,
          city,
          pincode,
          address1,
          address2,
          address3,
          source,
          aadhar,
          pan,
          occupation,
          homeOwner,
          creditScore,
          companyName,
          gst,
          callStatus,
          smsStatus,
          emailStatus,
          whatsappStatus,
        },
        {
          headers: {
            token: dm_admin,
          },
        }
      );
      if (res.data.success === true) {
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container p-2">
        {inputs !== null ? (
          <>
            <div className="row align-items-center justify-content-startn">
              <div className="col-md-6">
                <TextField
                  label="category"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="category"
                  value={inputs.category}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="tag"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="tag"
                  value={inputs.tag}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="name"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="name"
                  value={inputs.name}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="email"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="email"
                  value={inputs.email}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="phone1"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="phone1"
                  value={inputs.phone1}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="phone2"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="phone2"
                  value={inputs.phone2}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="phone3"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="phone3"
                  value={inputs.phone3}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="whatsapp"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="whatsapp"
                  value={inputs.whatsapp}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="city"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="city"
                  value={inputs.city}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="pincode"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="pincode"
                  value={inputs.pincode}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="address1"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="address1"
                  value={inputs.address1}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="address2"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="address2"
                  value={inputs.address2}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="address3"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="address3"
                  value={inputs.address3}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="source"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="source"
                  value={inputs.source}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="aadhar"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="aadhar"
                  value={inputs.aadhar}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="pan"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="pan"
                  value={inputs.pan}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="occupation"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="occupation"
                  value={inputs.occupation}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="homeOwner"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="homeOwner"
                  value={inputs.homeOwner}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="creditScore"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="creditScore"
                  value={inputs.creditScore}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="companyName"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="companyName"
                  value={inputs.companyName}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="gst"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="gst"
                  value={inputs.gst}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="callStatus"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="callStatus"
                  value={inputs.callStatus}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="emailStatus"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="emailStatus"
                  value={inputs.emailStatus}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="smsStatus"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="smsStatus"
                  value={inputs.smsStatus}
                  onChange={handleInputs}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="whatsappStatus"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mt-0"
                  autoComplete="off"
                  name="whatsappStatus"
                  value={inputs.whatsappStatus}
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="text-end">
              <Button variant="contained" color="primary" onClick={editLead}>
                Edit
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default EditLead;
