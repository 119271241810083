import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { Routes, Route } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./context/Theme";
import Auth from "./utility/Auth";
import Home from "./pages/Home";
import Users from "./pages/Users";
import SecretKey from "./pages/SecretKey";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import EditLead from "./pages/EditLead";

const App = () => {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Auth Component={Home} />} />
          <Route
            path="/lead/edit/:id"
            element={<Auth Component={EditLead} />}
          />
          <Route path="/users" element={<Auth Component={Users} />} />
          <Route path="/secretkey" element={<Auth Component={SecretKey} />} />
          <Route path="/profile" element={<Auth Component={Profile} />} />
          {/*  */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </ThemeProvider>
    </AppContextProvider>
  );
};

export default App;
