import React from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Profile = () => {
  const { admin } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="container p-2">
        {admin && (
          <div>
            <h2>
              <span className="fw-bold">Name</span>: {admin.name}
            </h2>
            <h2>
              <span className="fw-bold">Username</span>: {admin.username}
            </h2>
            <h2>
              <span className="fw-bold">Role</span>: {admin.role}
            </h2>
          </div>
        )}
        <hr />
        <div>
          <p>Want to create a new admin account</p>
          <Button
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => navigate("/signup", { replace: true })}
          >
            Click here
          </Button>
        </div>
      </div>
    </>
  );
};

export default Profile;
