import React from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadModal = ({
  downloadModal,
  setDownloadModal,
  leads,
  downloadLeads,
  downloadError,
  downloadStatus,
}) => {
  return (
    <>
      <Modal
        open={downloadModal}
        onClose={() => setDownloadModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div>
            <h2 className="mb-2">Download {leads.length}?</h2>
            <small>
              <em>
                <span className="fw-bold">Note: </span>
                Due to pagination, only 10,000 files will be downloaded at a
                time. To download the next 10,000 files, please navigate to the
                next page.
              </em>
            </small>
          </div>
          <p className="mt-4 mb-2 text-danger">{downloadError}</p>
          <div className="d-flex align-items-center justify-content-center gap-2">
            {downloadStatus === true ? (
              ""
            ) : (
              <Button
                onClick={() => setDownloadModal(false)}
                color="danger"
                className="text-white"
                variant="contained"
              >
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={downloadLeads}
              className="text-white"
            >
              Downloading
              {downloadStatus === true ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "white", marginLeft: "20px" }}
                />
              ) : (
                ""
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DownloadModal;
