import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import Cookies from "js-cookie";
import Loader from "../Loader";

const FilterModal = ({
  filterModal,
  setFilterModal,
  filterInputs,
  handleFilterInputs,
  getLeads,
  clearFilter,
  setPage,
  filterStatus,
}) => {
  const { url } = useContext(AppContext);
  //
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [tag, setTag] = useState([]);
  const [source, setSource] = useState([]);
  //
  useEffect(() => {
    const getCategories = async () => {
      const filters = sessionStorage.getItem("filters");
      if (filters) {
        const { category, tag, source } = JSON.parse(filters);
        setCategory(category);
        setTag(tag);
        setSource(source);
      } else {
        try {
          const dm_admin = Cookies.get("dm_admin");
          setLoading(true);
          const res = await axios.get(`${url}/category`, {
            headers: { token: dm_admin },
          });
          if (res.status === 200) {
            const { category, tag, source } = res.data;
            setCategory(category);
            setTag(tag);
            setSource(source);
            sessionStorage.setItem(
              "filters",
              JSON.stringify({
                category,
                tag,
                source,
              })
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };
    getCategories();
  }, []);

  const startFilter = () => {
    if (
      filterInputs.category == "" &&
      filterInputs.tag == "" &&
      filterInputs.source == ""
    ) {
      setFilterModal(false);
    } else {
      setPage(1);
      getLeads();
    }
  };
  return (
    <>
      <Modal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p>Filter</p>
              <IconButton
                aria-label="close"
                onClick={() => setFilterModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterInputs.category}
                    onChange={handleFilterInputs}
                    label="Category"
                    name="category"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {category &&
                      category.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {/*  */}
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Tag
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterInputs.tag}
                    onChange={handleFilterInputs}
                    label="tag"
                    name="tag"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {tag &&
                      tag.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {/*  */}
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "20px" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Source
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterInputs.source}
                    onChange={handleFilterInputs}
                    label="source"
                    name="source"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {source &&
                      source.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2">
            {filterStatus === false ? (
              <Button
                color="danger"
                variant="contained"
                className="text-white"
                onClick={clearFilter}
              >
                Clear
              </Button>
            ) : (
              ""
            )}
            <Button
              color="primary"
              variant="contained"
              className="text-white"
              onClick={startFilter}
            >
              Applying filter
              {filterStatus === true ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "white", marginLeft: "20px" }}
                />
              ) : (
                ""
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FilterModal;
