import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteUserModal from "../components/user/DeleteUserModal";

const Users = () => {
  const { url } = useContext(AppContext);
  //
  const [user, setUsers] = useState([]);
  const [user2, setUsers2] = useState([]);
  const getUsers = async () => {
    try {
      const dm_admin = Cookies.get("dm_admin");
      const res = await axios.get(`${url}/users`, {
        headers: {
          token: dm_admin,
        },
      });
      if (res.data.success === true) {
        console.log(res.data);
        setUsers(res.data.result);
        setUsers2(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails(item);
  };
  //
  const [deleteUserModal, setDeleteUsermodal] = useState(false);
  const [confirmUser, setConfirmUser] = useState(null);
  const deleteButton = (item) => {
    setDeleteUsermodal(true);
    setConfirmUser(item);
  };
  //
  const [searchInput, setSearchInput] = useState("");
  //
  useEffect(() => {
    if (searchInput !== "") {
      const filtered = user.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.mobile.toString().includes(searchInput.toLowerCase()) ||
          item.email.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setUsers2(filtered);
    } else {
      setUsers2(user);
    }
  }, [searchInput]);
  //
  return (
    <>
      <div className="container my-2">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-4">
            <TextField
              label="Search"
              variant="standard"
              fullWidth
              type="text"
              margin="normal"
              name="customerName"
              autoComplete="off"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        {/*  */}
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-background">
                <TableCell className="border" sx={{ minWidth: "100px" }}>
                  Sr No
                </TableCell>
                <TableCell className="border" sx={{ minWidth: "200px" }}>
                  Name
                </TableCell>
                <TableCell className="border" sx={{ minWidth: "200px" }}>
                  Mobile
                </TableCell>
                <TableCell className="border" sx={{ minWidth: "200px" }}>
                  Email
                </TableCell>
                <TableCell
                  className="border"
                  sx={{ minWidth: "100px" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user2.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className={index === rowIndex ? "table-background" : ""}
                  >
                    <TableCell className="border">{index + 1}</TableCell>
                    <TableCell className="border">
                      <span className="text-clamp">{item.name}</span>
                    </TableCell>
                    <TableCell className="border">
                      <span className="text-clamp">{item.mobile}</span>
                    </TableCell>
                    <TableCell className="border">
                      <span className="text-clamp">{item.email}</span>
                    </TableCell>
                    <TableCell className="border">
                      <ButtonGroup>
                        <IconButton
                          color="danger"
                          onClick={() => deleteButton(item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <DeleteUserModal
        deleteUserModal={deleteUserModal}
        setDeleteUsermodal={setDeleteUsermodal}
        confirmUser={confirmUser}
        getUsers={getUsers}
      />
    </>
  );
};

export default Users;
