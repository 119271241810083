import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

const UploadLeadModal = ({
  uploadLeadModal,
  setUploadLeadModal,
  getLeads,
  uploadLeadStatus,
  setUploadLeadStatus,
}) => {
  const { url } = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  //
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "text/csv" || file.name.endsWith(".csv")) {
        setError(false);
        setFile(file);
      } else {
        setError(true);
        setMessage("Please select a valid CSV file.");
        e.target.value = null;
        setFile(null);
      }
    }
  };
  //
  const uploadFile = async () => {
    if (file === null) {
      setError(true);
      setMessage("Please select a CSV file");
    } else {
      setUploadLeadStatus(true);
      const dm_admin = Cookies.get("dm_admin");
      console.log(dm_admin);
      const formdata = new FormData();
      formdata.append("file", file);
      try {
        const res = await axios.post(`${url}/lead`, formdata, {
          headers: {
            token: dm_admin,
          },
        });
        if (res.data.success === true) {
          setUploadLeadStatus(false);
          setFile(null);
          setError(false);
          setMessage(res.data.message);
          getLeads();
          setTimeout(() => {
            setUploadLeadModal(false);
          }, 2000);
        } else {
          setUploadLeadStatus(false);
          setError(true);
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={uploadLeadModal}
        onClose={() => setUploadLeadModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <div className="d-flex align-items-center justify-content-between">
              <p>Upload leads</p>
              <IconButton
                aria-label="close"
                onClick={() => setUploadLeadModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <TextField
              type="file"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
              variant="standard"
              onChange={handleFile}
            />
            <div className="text-end">
              <p className={error ? "text-danger" : "text-success"}>
                {message}
              </p>
              <Button
                variant="contained"
                className="text-white"
                onClick={uploadFile}
              >
                Upload
                {uploadLeadStatus === true ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white", marginLeft: "20px" }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default UploadLeadModal;
