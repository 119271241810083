import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, TextField, Button } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const { url } = useContext(AppContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  //
  const [checked, setChecked] = useState(false);
  const handleCheckbox = () => {
    setChecked(!checked);
  };
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = inputs;
    //
    if (!username) {
      setIsErr(true);
      setMsg("Enter username");
    } else if (!password) {
      setIsErr(true);
      setMsg("Enter password");
    } else {
      const res = await axios.post(`${url}/admin/login`, {
        username,
        password,
      });
      if (res.data.success === true) {
        Cookies.set("dm_admin", res.data.token);
        setIsErr(false);
        setMsg("Login successful");
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 2000);
      } else {
        setIsErr(true);
        setMsg(res.data.message);
      }
    }
  };
  useMemo(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 2000);
    }
  }, [msg]);
  return (
    <div className="d-flex align-items-center justify-content-center px-2">
      <Card style={{ maxWidth: "400px", width: "400px", margin: "10px auto" }}>
        <div className="p-2">
          <div className="auth-title-box rounded">
            <h2>Data Management</h2>
          </div>
        </div>
        <CardContent>
          <h2>Login</h2>
          <form>
            <TextField
              label="Username"
              variant="standard"
              fullWidth
              margin="normal"
              className="mt-0"
              autoComplete="off"
              name="username"
              value={inputs.username}
              onChange={handleInputs}
            />
            <div className="password-div">
              <TextField
                label="Password"
                type={checked ? "text" : "password"}
                variant="standard"
                fullWidth
                margin="normal"
                autoComplete="off"
                name="password"
                value={inputs.password}
                onChange={handleInputs}
              />
              <div className="password-show" onClick={(e) => handleCheckbox(e)}>
                {checked === true ? (
                  <VisibilityOffIcon color="primary" />
                ) : (
                  <VisibilityIcon color="primary" />
                )}
              </div>
            </div>
            <div className="text-end mt-4">
              <p className={isErr ? "text-danger" : "text-success"}>{msg}</p>
              <Button
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
          </form>
          <hr />
          <p>Want to create a new admin account?</p>
          <Button
            variant="contained"
            className="text-white"
            color="primary"
            onClick={() => navigate("/signup", { replace: true })}
          >
            Signup
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
