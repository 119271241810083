import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../../context/AppContext";
import Cookies from "js-cookie";
import axios from "axios";

const DeleteLeadModal = ({
  deleteLead,
  setDeleteLead,
  confirmLead,
  getLeads,
}) => {
  const { url } = useContext(AppContext);
  //
  const [message, setMessage] = useState("");
  const deleteLeadFunction = async () => {
    const dm_admin = Cookies.get("dm_admin");
    try {
      const res = await axios.delete(`${url}/lead/${confirmLead._id}`, {
        headers: {
          token: dm_admin,
        },
      });
      if (res.data.success === true) {
        setDeleteLead(false);
        getLeads();
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={deleteLead}
        onClose={() => setDeleteLead(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <h2 className="mb-2">Delete {confirmLead && confirmLead.name}?</h2>
          </>
          <p className="text-danger mb-2">{message}</p>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              onClick={deleteLeadFunction}
              color="danger"
              className="text-white"
              variant="contained"
            >
              Yes
            </Button>
            <Button
              onClick={() => setDeleteLead(false)}
              color="primary"
              variant="contained"
              className="text-white"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteLeadModal;
